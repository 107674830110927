.container-text{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-block: 30px;
    display: none;
    
}
.container-text h4{
    color: #000 !important;
    font-size: 20px;
    text-align: center;
}
@media screen and (max-width:550px) {
    .container-text h4{
        
    }
}
/* ************************************************************* */
/* ******* SECTION DE CATEGORIAS DE ICONOS MOBILES***** */
/* ************************************************************* */
.categorias-con-imagenes {
    position: relative;
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
  }
  
  .div-respon-categorias-con-imagenes {
    height: auto;
    /*responsive*/
    max-width: 1000px !important;
  
  
  }
  
  .navegation-mobil {
    width: 100%;
    height: auto;
    /*background-color: #fff;*/
    /*background-color: #7cb9ee;*/
    /*background-color:#f5f5f5;*/
    /*background: #ECE9E6;  /* fallback for old browsers */
    /*background: -webkit-radial-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
    /*background: radial-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding-top: 10px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 20px;
    /*border: 1px solid #bdb8b8;*/
  
  }
  
  .navegation-mobil-iconos-1 {
    width: 100%;
    /*background-color:#efebe9 ;*/
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  
  }
  
  .icon-titulo-1 {
    width: 120px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .imgen-icono {
    width: 76px !important;
    height: 76px !important;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color:#e0e0e0;*/
    /*background-color:/* rgb(180, 180, 180) #999696 ;*/
    /*background-color: rgb(162, 160, 229);*/
  
    /*background-color: #bdbdbd;*/
    /*background-color: #968d8d;*/
    /*background-color: #f1f8e9;*/
    background-color: #f3e5f5 !important;

  
    /* border: 1px solid #d8d5d5 ; */
    border: 1px solid rgb(69, 69, 69);
  
  }
  
  .imgen-icono img {
    width: 45px;
    height: 45px;
  }
  
  .titulo-link-mobil {
    font-size: 15px;
    color: #240101;
  
    font-family: 'Play', sans-serif;
    font-weight: 600;
  }
  
  .navegation-mobil-encabezado {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .navegation-mobil-encabezado h4 {
    font-size: 25px;
    color: #000;
    font-family: 'Play', sans-serif;
    font-weight: bold;
  }
  
  
  /*
  @media screen and (max-width:1400px) {
    .div-respon-categorias-con-imagenes {
      height: auto;
  
      max-width: 900px;
      min-width: 900px;
  
  
    }
  }
  
  @media screen and (max-width:1100px) {
    .div-respon-categorias-con-imagenes {
      height: auto;
    
      max-width: 800px;
      min-width: auto;
  
  
  
    }
  }
  
  @media screen and (max-width:600px) {
    .div-respon-categorias-con-imagenes {
      width: 100% !important;
      padding: 5px 5px;
    }
  }
  
  @media screen and (max-width:550px) {
  
  
    .imgen-icono img {
      width: 25px !important;
      height: 25px !important;
    }
  
  }
  
  @media screen and (max-width:450px) {
  
  
    .imgen-icono {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
    }
  
  }
  
  @media screen and (max-width:350px) {
  
    .imgen-icono img {
      width: 60px !important;
      height: 60px !important;
    }
  
    .imgen-icono {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
    }
  
  }
  */
  @media screen and (max-width:800px) {
    .categorias-con-imagenes{
      display: none;
    }
  
  }
  @media screen and (max-width:550px) {
    .imgen-icono img {
      width: 40px;
      height: 40px;
    }
  
    .imgen-icono {
      width: 63px !important;
      height: 63px !important;
  
  
    }
    .titulo-link-mobil {
      font-size: 12px;
      
    }
    .icon-titulo-1 {
      width: 90px !important;
    }
  
  
  }
  
  /* ************************************************************* */
  /* *******   FIN DE LA SECTION DE CATEGORIAS DE ICONOS MOBILES ***** */
  /* ************************************************************* */
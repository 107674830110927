.container-search-input-agencia {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

.div-search-input-agencia {
    display: flex;
    flex-direction: row;
    width: 30%;
}

.div-search-input-agencia input[type=text] {
    height: 20px !important;
    width: 95% !important;
    /*border-bottom: 2px solid #616161 !important ;*/
    border-bottom: 2px solid #212121 !important;
    padding: 5px !important;
    box-shadow: none !important;
    margin-top: 27px !important;
    box-shadow: none !important;
    color: #212121 !important;
    background-color: #fff !important;

}

.container-search-input-form input[type=text]::placeholder {
    color: #212121 !important;

}

.container-search-input-form input[type=text]:focus {
    /*border-bottom: 2px solid #616161 !important;*/
    border: 1px solid #929292 !important;
    box-shadow: none !important;
}
@media screen and (max-width:1200px) {

    .div-search-input-agencia {
        width: 50%;
    }


}
@media screen and (max-width:700px) {

    .div-search-input-agencia {
        width: 95%;
    }


}
.container-info-profil-agence {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container-info-profil-agence-resp {
    max-width: 900px;
}

.info-profil-agence-title-container {
    margin-top: 20px;
    padding-inline: 20px;
    background-color: #cfeafd;
    padding-block: 20px;
}



.info-profil-agence-brevedescrip-container {
    padding-inline: 20px;
    padding-block: 20px;
    margin-top: 0px;
    border-radius: 10px;
    /*background-image: url("https://res.cloudinary.com/mumbex/image/upload/v1666540800/bg8w_npfohg.png");*/
    /*border: 1px solid rgb(102, 102, 102);*/


}
.info-profil-agence-brevedescrip-container h5 {
    text-align: center;
    color: #000000!important;
    font-weight: 700 !important;
}

.info-profil-agence-brevedescrip-container p {
    text-align: center;
    color:#000!important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

@media screen and (max-width:650px) {
   .info-profil-agence-title-container h5 {
        
    }
    .info-profil-agence-brevedescrip-container p {
        font-size: 19px !important;
    }
}

.info-profil-agence-contacts-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-bottom: 15px;

}

.info-profil-agence-contacts-container .link-contact-profil-agence {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px !important;
    height: 40px;
    margin-top: 10px;
}

.info-profil-agence-contacts-container .icono-profil-agence {
    margin-top: 5px;
    margin-right: 10px;
}

.info-profil-agence-contacts-container .contact-text-profil-agence {
    margin-top: 3px;
    font-weight: 600;
    color: #18056d;
    font-size: 19px !important;
    text-decoration:1px underline;
}
.info-profil-agence-contacts-container .link-contact-profil-agence-email{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #212121;
    font-size: 17px;
}
.icono-profil-agence-email{
 
    
}
.info-profil-agence-descrip-service-list-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-inline: 20px;
    padding-block: 20px;
    border-radius: 10px;
    background-image: url("https://res.cloudinary.com/mumbex/image/upload/v1666540800/bg8w_npfohg.png");
    border: 1px solid rgb(102, 102, 102);

}
.title-service-list{
    display: flex;
    justify-content: center;
}
.title-service-list h5{
    color: #025163 !important;
    font-weight: 500 !important;
}
.service-list{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.service-list h6{
    text-align: center;
    font-size: 20px !important;
    font-weight: 400;
    color:rgb(41, 0, 0)!important;
}

@media screen and (max-width:650px) {

    .service-list h6{
         font-size: 18px !important;
     }
 }

.container-icono-abrir-mas{
    display: flex;
    justify-content: center;
}
.container-icono-abrir-mas a{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    color: blue;
    font-weight: 600;
    font-size: 20px;
}
/***************HACER APARECER CARD-FOTO-HORIZONTAL***********************/
.displayCardMobil{
    display: none;
}
.displayCardBigScreen{
    display: block;
    
}
.cardVerticalContainer {
    width: 100%;
    display: flex;
    justify-content: center;

}

.cardVerticalContainerResp {
    max-width: 1000px;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.text-publicaciones-recientes{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.text-publicaciones-recientes h2{
    text-align: center;
    font-size: 23px !important;
    color: #240101 !important;
    font-weight: 500;
}

.cardVertical {
    width: 220px;
    height: 320px;
    border-radius: 7px;
    border: 1px solid rgb(189, 189, 189);
    margin-inline: 10px;
    margin-bottom: 20px;

}

.cardVerticalImg {
    width: 100%;
    height: 160px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    
}

.cardVerticalImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.cardVerticalProductName {
    width: 100%;
}

.cardVerticalProductName h6 {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    color: #212121;
}

.cardVerticalProductDescripcion {
    width: 100%;
}

.cardVerticalProductDescripcion h6 {
    text-align: center;
    font-weight: 550;
    font-size: 15px;
    color: #494949;
}

.cardVerticalProductPrecio {
    width: 100%;
}

.cardVerticalProductPrecio h6 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: red;
}

.cardVerticalProductTiempo {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cardVerticalProductTiempo h6 {
    font-weight: 600;
    font-size: 12px;
    color: rgb(13, 1, 70);

}

.cardVerticalProductTiempo .iconRelog {
    color: rgb(250, 0, 0);
    ;
    margin-right: 2px;
}
.cargarDatosmas{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
}


@media screen and (max-width:1000px) {
    .cardVerticalContainerResp {
        justify-content: center;

        
    }
    .cardVertical {
        width: 220px;
        height: 320px;
        margin-inline: 5px;
        margin-bottom: 20px;
    }

    .cardVerticalImg {
        width: 100%;
        height: 190px;
    }
    .cardVerticalProductName h6{
        font-size: 15px;
    }
}

@media screen and (max-width:940px) {


    .cardVertical {
        width: 200px;
        margin-inline: 10px;

    }


}
@media screen and (max-width:900px) {


    .cardVertical {
        width: 200px;
        margin-inline: 5px;


    }


}
@media screen and (max-width:850px) {

    .cardVertical {
        width: 31%;
        margin-inline: 5px;


    }


}


/***************HACER APARECER CARD-FOTO-HORIZONTAL***********************/
@media screen and (max-width:600px) {

    .cardVertical {
        width: 45%;
    }
    .displayCardMobil{
        display: block;
    }
    .displayCardBigScreen{
        display: none;
    }


}

@media screen and (max-width:500px) {

    .cardVertical {
        height: 300px;
        width: 48%;
    }

    .cardVerticalImg {
        height: 150px;
    }

    .cardVerticalProductPrecio h6 {
        font-size: 18px;
    }
    .cardVerticalProductDescripcion h6{
        font-size: 14px;
    }
    .cardVerticalProductName h6{
        font-size: 16px;
    }

}
@media screen and (max-width:335px) {
    .cardVerticalProductName h6{
        font-size: 15px;
    }
}
@media screen and (max-width:260px) {

    .cardVertical {
        height: 270px;
        width: 48%;
    }

    .cardVerticalImg {
        height: 120px;
    }

    .cardVerticalProductPrecio h6 {
        font-size: 13px;
    }
    .cardVerticalProductDescripcion h6{
        font-size: 11px;
    }
    .cardVerticalProductName h6{
        font-size: 13px;
    }
    .cardVerticalProductTiempo h6{
        font-size: 9px;
    }

}



/* ------------------------- */
/*CARROUSE DE HOME PAGE PUBLICIDAD*/
/* ------------------------- */

.containerCarouselPub {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  
  }
  
  .carouselPub {
    width: 800px;
    height: 300px;
  
  }
  
  .sliderContainer {}
  
  .itemCarousel {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .itemCarouselImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  
  }
  
  .itemCarouselImg img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  
  }
  
  .itemCarousel h3 {
    text-align: center;
  }
  
  @media screen and (max-width:1100px) {
    .containerCarouselPub {
      display: flex;
      justify-content: center;
  
    }
  
    .itemCarouselImg img {
      width: 100%;
  
    }
  
  }
  
  @media screen and (max-width:820px) {
    .containerCarouselPub {
      display: flex;
      justify-content: center;
  
    }
  
    .itemCarouselImg img {
      width: 100%;
  
    }
  
    .carouselPub {
      width: 97%;
    }
  
  }
  
  @media screen and (max-width:700px) {
    .carouselPub {
      height: 250px;
    }
  
    .containerCarouselPub {
      display: flex;
      justify-content: center;
  
    }
  
    .itemCarouselImg img {
      width: 100%;
  
    }
  
    .carouselPub {
      width: 97%;
    }
  
    .itemCarouselImg img {
      height: 250px;
      object-fit: contain;
    }
  
  }
  
  @media screen and (max-width:450px) {
    .carouselPub {
      height: 200px;
    }
  
    .itemCarouselImg img {
      height: 200px;
      object-fit: contain;
  
    }
  }
  


.slick-list {
    position: relative;
    border-top: 20px !important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border: 1px solid rgb(189, 189, 189) !important;
    display: block;
    height: 300px;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  @media screen and (max-width:700px) {
    .slick-list {
      height: 250px;
    }
  }
  
  @media screen and (max-width:450px) {
    .slick-list {
      height: 200px;
    }
  }
  /* ------------------------- */
  /* FIN DE CARROUSEL DE PUBLICIDAD */
  /* ------------------------- */



  /*

    .carrousel-pub {
    position: relative;
    top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
  }
  
  .fff {
    width: 500px !important;
    height: 200px !important;
  }
  
  .contenedor-responsive {
    background-color: #8479C2;
    height: auto;
    max-width: 100px;
  }
  
  .carrousel-para-pub {
    width: 500px !important;
  }
  
  .item-carousel-publicidad {
    width: 100%;
    display: flex;
    height: auto;
  
  }
  
  .item-carrosel-publicidad-contenido {
    width: 200px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .item-carrosel-publicidad-contenido img {
    width: 10% !important;
  }
  
  .btn-para-publicidad {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
  
  }


  */
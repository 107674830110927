.productos-ver-cat {
    width: 100%;
    /* border-right: 2px solid rgba(7, 48, 233, 0.1);  */
  }
  
.respon-productos-ver-cat {
    display: block;

}

.fila-de-targetas-ver-cat {
    width: 100%;
    display: flex;
    justify-content: center;
  
}
.card-container-resp-targetas-ver-cat{
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 100px;
    width: 100%;
}
.cardCategoria-ver-cat-2 {
    width: 220px;
    height: 320px;
    border-radius: 7px;
    border: 1px solid rgb(189, 189, 189);
    margin-inline: 10px;
    margin-bottom: 20px;
  
  }
  
.card-foto-ver-cat {
    width: 100%;
    height: 180px ;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: flex;
    justify-content: center;
    
}

.card-foto-ver-cat img {
    width: 100%;
    height: 100%;
    object-fit: contain ;
    padding: 2px;
}

.cardVerticalProductName-ver-cat {
    width: 100%;
}

.cardVerticalProductName-ver-cat h6 {
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    color: #212121;
}

.cardVerticalProductDescripcion-ver-cat {
    width: 100%;
}

.cardVerticalProductDescripcion-ver-cat h6 {
    text-align: center;
    font-weight: 550;
    font-size: 15px;
    color: #494949;
}

.cardVerticalProductPrecio-ver-cat {
    width: 100%;
}

.cardVerticalProductPrecio-ver-cat h6 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: red;
}

.cardVerticalProductTiempo-ver-cat {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cardVerticalProductTiempo-ver-cat h6 {
    font-weight: 600;
    font-size: 12px;
    color: rgb(13, 1, 70);

}

.cardVerticalProductTiempo-ver-cat .iconRelog {
    color: rgb(250, 0, 0);
    margin-right: 2px;
}

.cargarDatosmas-ver-cat{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100px;
}

@media screen and (max-width:560px) {

    .cardCategoria-ver-cat-2 {
        margin-inline: 5px;
      
      }

}

@media screen and (max-width:500px) {


    .cardCategoria-ver-cat-2 {
        width: 100%;
        height: 300px;
      
      }


}
@media screen and (max-width:450px) {


    .cardCategoria-ver-cat-2 {
        width: 100%;
        height: 330px;
      
    }
    .card-foto-ver-cat {
        width: 100%;
        height: 200px !important;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        display: flex;
        justify-content: center;
        
    }


}

@media screen and (max-width:380px) {


    .cardCategoria-ver-cat-2 {
        width: 100%;
        height: 330px;
      
    }
    .card-foto-ver-cat {
        width: 100%;
        height: 200px ;
        
        
    }


}

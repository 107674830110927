.modal-edite-product-container-global .modal {
    max-width: 400px !important;
}

.modal-edite-perfil {
    max-width: 400px !important;

}

@media screen and (max-width:560px) {
    .modal-edite-product-container-global .modal {
        width: 96% !important;
    }

    .modal-edite-product-container-global .formulario-agregar-form {
        width: 100% !important;

    }
}

@media screen and (max-width:420px) {
    .modal-edite-product-container-global .modal {
        width: 96% !important;
    }

    .modal-edite-product-container-global .formulario-agregar-form {
        width: 100% !important;

    }
}

@media screen and (max-width:370px) {
    .modal-edite-product-container-global .modal {
        width: 96% !important;
    }

    .modal-edite-product-container-global .formulario-agregar-form {
        width: 100% !important;

    }

}

@media screen and (max-width:350px) {
    .modal-edite-product-container-global .modal {
        width: 96% !important;
    }

    .modal-edite-product-container-global .formulario-agregar-form {
        width: 100% !important;

    }

    .img-preview {
        width: 90px;
        height: auto;
    }
}
.articulosCarrouselContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-block: -50px;
}

.articulosCarrouselContainerTextYCaRD {
  display: flex;
  flex-direction: column;
  max-width: 950px;
  border-radius: 5px;
  padding-inline: 50px;

}

.articulosCarrouselContainerResponContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TextCenterDiv {
  display: flex;
  justify-content: center;
}

.TextCenterDiv h2 {
  text-align: center;
  font-size: 23px !important;
  color: #240101 !important;
  font-weight: 500;
}

.articulosCarrouselContainerRespon {
  width: 900px;

}


.targetasCarousel {
  position: relative;
  width: 94%;
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow: hidden;
  /*background-color: rgba(255, 254, 254, 0.74);*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 5px !important;
  margin-block: 10px;
  border: 1px solid rgb(61, 61, 61); /*rgb(224, 224, 224);*/
}

.owl-next,
.owl-prev {
  position: absolute !important;
  top: 30px;
  width: 40px;
  height: 40px;
  font-size: 60px !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  z-index: 1000;
}

.owl-prev {
  left: -50px;
}

.owl-next {
  right: -50px ;
}
.owl-prev span {
  margin-right: 10px;
  
}
.owl-next span {
  margin-left: 8px;
  
}
.owl-next span,
.owl-prev span {
  margin-bottom: 8px;
  color: #fff !important;
  font-size: 35px !important;
}


.owl-prev:hover,
.owl-next:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.owl-prev:hover.owl-prev span {
  color: #fff !important;
}

.owl-next:hover.owl-next span {

  color: #fff !important;
}

.contenedorImagenCarrosel-container{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedorImagenCarrosel {

  width: 100% ;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-color: rgba(0, 0, 0, 0.2);*/
  /*border: 1px solid #ff9e97;*/


}

.contenedorImagenCarrosel img {
  width: 100px;
  height: 150px;
  object-fit: contain;
  border-radius: 0px !important;
  border: none !important;
}

.descripcionCarrousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-inline: 1px;
  /*background-color: rgba(0, 0, 0, 0.2);*/


}

.descripcionCarrousel p {
  margin: 0px 0px;
  font-weight: 400;
  line-height: 1.2;
  color: #eee;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  overflow-wrap: break-word;
}

.descripcionCarrousel .title-agence-tienda {
  font-weight: 600;
  margin-top: -10px;
  color: #f44336;
  font-size: 17px;
  text-align: center;

}

.descripcionCarrousel .descripcion-agence-tienda {
  text-align: center;
  color: #757575;
  font-size: 15px;
  margin-top: 3px;
  padding-inline: 5px;
  font-weight: 500;


}



.descripcionCarrousel .precioCarrousel {
  font-size: 19px;
  font-weight: 600;
  font-weight: bold;
  color: red;
  margin-bottom: 5px !important;
}

.visto {
  width: 30%;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}

.stockProductoCarrousel {
  position: absolute;
  color: #757575;
  top: 0px;
  width: 100%;
  height: 20px;
  display: flex;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  justify-content: end;
  align-items: center;
  background: transparent;
  z-index: 100;
}

.stockProductoCarrousel p {
  margin-right: 10px;
  background-color: #fff;
  border-radius: 20px;
  padding-inline: 5px;
  font-size: 15px !important;
}



@media screen and (max-width:970px) {
  .articulosCarrouselContainerTextYCaRD {
    max-width: 100%;
    padding-inline: 0px;
  }

  .articulosCarrouselContainerRespon {
    width: 100%;
  }

  .targetasCarousel {
    margin-left: 7px !important;
  }

  .descripcionCarrousel .title-agence-tienda {
  }
}
@media screen and (max-width:670px) {

  .owl-next,
  .owl-prev {
    display: none !important;
  }

}

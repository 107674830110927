#modalInfoProduct{
    width: 500px;
    border-radius: 5px !important;
    top: 10px !important;
}
.container-foto-producto {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.foto-producto-img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-block: 5px;

}

.foto-producto-img img {
    width: 100%;
    object-fit: contain;
}
@media screen and (max-width:580px) {
    #modalInfoProduct{
        width: 100% !important;
    }
}
/***********************************************************************************/
/* ------------------------- */
/* PRODUCTO INFO PRODUCTO */
/* ------------------------- */
.containerGlobal {
    
}



.product-info {
    width: 100%;
    margin-top: 20px;
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: 20px;
}

.product-info-respon {
    width: 500px;
    height: auto;
    background-color: transparent;
}

.articulo-description {
    width: 100%;
    flex-direction: column;
}

.articulo-description-info-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-block: -15px;
}

.articulo-description-info-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-block: -15px;

}

.product-name {
    font-size: 25px !important;
    font-weight: 500;
    margin-bottom: -2px;
}

.product-description {
    font-size: 18px !important;
    font-weight: 400;
}

.product-sub {
    font-size: 20px !important;
    font-weight: 700;
    margin-bottom: 0px;
    color: #212121 !important;
}

.product-price {
    font-size: 30px !important;
    font-weight: 500;
    color: red;
    margin-block: -1px;
}


.localizacioTextProduct {
    color: #212121 !important;
    font-size: 17px !important;
    font-weight: 500;
    margin-top: 30px !important;
}


.product-info-contacto {
    display: flex;
    flex-direction: column;

}

.contactCenterWordl {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
}

.product-info-contacto-link {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.product-info-contacto-link a {
    margin-block: 10px;
    display: flex;
    align-items: center;
    text-align: center;

}
.product-info-contacto-link a span{
    font-size: 20px !important;
    color:  #000 !important;
    font-weight: 500 !important;
    margin-inline: 5px;

}
.product-info-contacto-link .whatsapp {
    color: #35db1b !important;
    

}

.product-info-contacto-link .telefono {
    color: #1b75eb;
}


/********   CONTENEDOR DEL CARROUSE DE IMAGENES DEL PRODUCTO   ********/

.ver-produto-contenedor {
    margin-top: 70px !important;
    display: flex;
    height: auto;
    justify-content: center;

}

.ver-produto-respon {
    width: auto;
    height: auto;
    display: flex;

}

.ver-producto-img {
    width: 500px;
    /*background-color: #ECE9E6 ;*/
    /*background-color: rgba(52, 52, 66, 0.3);*/
    /*background-color: rgba(137, 170, 231, 0.1);*/
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s !important;

}

.imagen_ver_producto {
    width: 100% !important;
    height: 100% !important;
    transition: 0.02s;
}

.ver-producto-img img {
    width: 100%;
    height: 100%;
    transition: 0.02s;
}

.item img {
    border-radius: 10px;
    border: 2px solid #fff;
    width: 100%;
    height: 100%;
    transition: 0.02s;
}

.ver-producto-elegir-img {
    width: 120px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    margin-right: 20px !important;
    /*background-color: #ECE9E6 ;*/
    /*background-color: rgba(18, 14, 238, 0.05);*/
    /*background-color: rgba(52, 52, 66, 0.3);*/
    /*background-color: rgba(137, 170, 231, 0.1);*/
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.ver-img-item {
    width: 100px;
    height: auto;
    margin: 5px 5px;

}


.ver-img-item img {
    width: 100%;
    transition: 0.02s;
    border: 2px solid transparent;
}

@media screen and (max-width:580px) {
    .background-ver-img {
        /*background-color: rgba(18, 14, 238, 0.05);*/

    }

    .ver-producto-img {
        background-color: transparent;
    }

    .ver-producto-elegir-img {
        background-color: transparent;
    }

    .ver-produto-contenedor {
        margin-top: 60px !important;
        margin-bottom: 10px !important;

    }

    .margin-top-description {
        margin-top: -100px;
    }

    /***border-abajo****/
    .border-abajo {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;

    }

    .top-margen {
        margin-top: 70px;
    }

    /****fin de border abajo****/
    .ver-produto-respon {
        width: 100%;
        flex-direction: column-reverse;
        height: auto;

    }

    .ver-producto-img {
        width: 100%;
        height: auto;
        margin-right: 0px !important;
        transition: 0.2s;

    }

    .ver-producto-img img {
        width: 100%;
        display: flex;
        transition: 0.2s;



    }

    .ver-producto-img img {
        width: 100%;
        transition: 0.02s;
    }

    .ver-producto-elegir-img {
        width: 100%;
        flex-direction: row;
        margin-right: 0px !important;
        margin-top: 10px !important;
        border: 0px !important;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        height: auto;
    }
}








/********   CONTENEDOR DEL CARROUSE DE IMAGENES DEL PRODUCTO   ********/



/* ------------------------- */
/* FIN DE PRODUCTO INFO PRODUCTO */
/* ------------------------- */
/*****************************************************************/
.container-subir-imagenes-agencia{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.container-subir-imagenes-agencia-resp{
    max-width: 900px;
}
.container-text-indicar-seleccionar-fotos{
    padding-inline:10px ;
}
.container-text-indicar-seleccionar-fotos blockquote{
    border-color: blue !important;
}
.container-text-indicar-seleccionar-fotos blockquote p{
    font-weight: 500;
    text-align: left;
    color: blue;
}
.subir-imagenes-agencia-lista{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.subir-imagenes-agencia-lista img{
    width: 220px;
    object-fit: contain;
    border: 1px solid #b1b1b1;
    border-radius: 10px;
    margin: 5px;
}
.conatainer-seleccionar-imagenes-input{
    width: 100%;
    display: flex;
    justify-content: center;
}
.selecionar-imagenes-agencia{
    display: flex;
    width: 200px;
    height: 200px;
}
.selecionar-imagenes-agencia a{
    width: 100%;
    height: 100%;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.selecionar-imagenes-agencia a p{
    color: darkgray !important;
}

.conatainer-boton-subir-imagenes-agencia{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
}
.conatainer-boton-subir-imagenes-agencia button{
   background-color: blue !important;
   font-weight: 500;
   display: flex;
   align-items: center;
   justify-content: center;
}
.conatainer-boton-subir-imagenes-agencia button:hover{
    background-color: blue !important;
 }

 .conatainer-boton-subir-imagenes-agencia button span {
    margin-top: 6px !important;
}
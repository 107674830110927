/* ------------------------- */
/* formaulrio de registrarse y iniciar*/
/* ------------------------- */
.formularioReI {
  display: flex;
  justify-content: center;
  align-items: center !important;

}

.formularioReI-respon {

  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.formularioReI-form {
  width: 450px;
  /*background-color: #ECE9E6;*/
  background-color: transparent;
  border-radius: 10px;
  height: auto;
  margin-top: 100px !important;
  margin-bottom: 20px;

}
.container-info-text-registro{
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.container-info-text-registro p{
  font-size: 12px !important;
  margin-left: 10px;
  color: red;
  text-align: left !important;
  margin-block: 0px !important;
}
.container-info-text-registro span{
  font-size: 12px !important;
  margin-left: 10px;
  text-align: left !important;
  font-weight: 500;
}
.formularioReI-respon-cambio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formularioReI-respon-cambio a {
  margin-top: 2px !important;
  margin-left: 10px;
  color: blue;
  font-size: 17px !important;
  font-weight: bold;
}

.formularioReI-respon-cambio p {
  font-weight: bold;
  color: #000;
}

.conatainerSelectFormRegister {
  margin-left: 3px;
}

.selectFormRegister {
  border-radius: 10px !important;
  padding-left: 10px !important;
}

.formularioReI-form input {
  border-width: 2px ;
  border-color: #212121;
  box-shadow: none !important;
  background-color: #fff !important;
  font-size: 16px !important;
  padding-left: 10px !important;

}
.formularioReI-form input::placeholder {
  color: gray !important;
}

.textarea-registro textarea{
  border-bottom: 2px solid blue !important;

}
.textarea-registro textarea::placeholder {
  font-size: 16px !important;
}
.container-img-logo-profil{
  width: 100%;
  display: flex;
  justify-content: center;
}
.container-img-logo-profil img{
  width: 100%;
  object-fit: contain;
}
.formularioReI-form-titulo p {
  font-size: 25px !important;
  font-weight: bold !important;
}

.formularioReI-form .error {
  color: red;
  font-weight: bold !important;
}

.respuestaError p {
  color: #e40000;
  font-weight: 500;
  font-size: 20px !important;
}


.boton-submit-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.boton-submit-container button {
  background-color: #efebe9 !important;
  color: #000;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  height: 40px;
  color: #212121 !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.boton-submit-container button span {
  margin-top: 12px !important;
}


.spinner-login {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width:750px) {
  .formularioReI-form {
    margin-top: 100px !important;

  }
}
@media screen and (max-width:550px) {
  .formularioReI-form {
    width: 400px;

  }
}
@media screen and (max-width:450px) {
  .formularioReI-form {
    width: 350px;

  }
}
@media screen and (max-width:370px) {
  .formularioReI-form {
    width: 300px;

  }
}
@media screen and (max-width:325px) {
  .formularioReI-form {
    width: 255px;

  }
}
@media screen and (max-width:280px) {
  .formularioReI-form {
    width:100%;

  }
}
/* ------------------------- */
/* fin formaulrio de registrarse y iniciar*/
/* ------------------------- */
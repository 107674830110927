body {
  background-color: #fff;

}
.container-global-agencias-tiendas{
}

a {
  font-family: 'Play', sans-serif;
  outline: none;
}

h5,
h1,
h3,
h4 {
  font-family: 'Play', sans-serif;
  outline: none;
  font-size: 18px;
}

h5 {
  color: #e65100 !important;
}

h2 {
  font-size: 25px !important;
}

p {
  font-size: 16px !important;
  text-align: center;
  color: black;
  font-weight: bold;
}

.spinner-home {
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.spinner-cat {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


}

@media screen and (max-width:500px) {
  .spinner-home {
    margin-top: -200px;
  }
}


.categoria-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinnerContainer {
  background-color: #050505;
  margin-right: 200px;
}

.altura-verc-ategorias {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;


}

/**sin resultado de busqueda ver-categoria*/
.sin-resultado {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 800px;
  align-items: center;
}

.ceroResultado {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50%;
  align-items: center;
}

.ceroResultado h1 {
  font-size: 17px;
}

.iconResultadoCeros {
  font-size: 350px !important;
  color: #424242;
  margin-top: 100px;
}

.sin-resultado h1 {
  color: #424242;
  font-size: 25px !important;
}

@media screen and (max-width:600px) {
  .sin-resultado {
    height: 100px;
  }
}

/*******************/
/***paginacion*/
.activado {
  background-color: red !important;
  color: #1b1b11 !important;
}

/**FIN paginacion***/
@media screen and (max-width:500px) {
  .spinner-home {
    height: 450px;
  }
}

@media screen and (max-width:1200px) {
  .altura-verc-ategoria {
    height: 500px;
  }

}

@media screen and (max-width:500px) {
  .altura-verc-ategoria {
    height: 400px;
  }

}

/***************************/
/* BOTON FLOTANTE*/
/****************************/
.flotante {
  position: fixed;
  bottom: 50px;
  right: 200px;
  background-color: rgb(0, 0, 175) !important;
  box-shadow: none !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  font-size: 12px !important;
  opacity: 0.9;
  border: 2px solid #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px !important;
  z-index: 1000;

}

.flotante span {}

@media screen and (max-width:1500px) {
  .flotante {
    right: 50px;
  }
}

@media screen and (max-width:1300px) {
  .flotante {
    right: 0px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

/***************************/
/* FIN DE BOTON FLOTANTE*/
/****************************/

/* ------------------------- */
/* NAVEGATION BAR */
/* ------------------------- */
.contenedor-sticky {
  position: fixed;
  top: 0px;
  margin: auto;
  width: 100%;
  /*background-color: #fff;*/
  justify-content: center;

  /*background:  rgb(197, 196, 196) !important;*/
  /*background:  rgb(243, 241, 241) !important;*/
  /*background-color: #e6fbfd;*/
  /*background-color: #efebe9;*/
  background-color: #212121;
  /* border-bottom: 1px solid #efebe9 !important; */
  border-bottom: 1px solid rgb(189, 189, 189);

  z-index: 1000 !important;
}

.menu-sticky {
  height: 50px;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.contenedor-enlaces-nav-sticky {
  height: 50px;
  width: 90%;
  display: flex;

}

.btn-departamentos-sticky {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 16%;


}

.dropppdown-content-sticky {
  height: auto;
  display: none;
  width: auto;
  position: absolute;
  top: 50px;
  min-width: auto;
  z-index: 100 !important;
  transition: 0.5s !important;
}

.dropppdown-content-sticky .collection-1 {
  float: left;
  width: auto;
  margin: 0px 0px;
  color: #1b1b1b;
  border-bottom: none;
  background-color: transparent;
}

.collection-1 a {
  display: flex !important;
  align-items: center !important;
  color: #000 !important;
  font-size: 14px !important;
}

.collection-1 img {
  width: 20px;
  margin-right: 10px;
}

.dropppdown-content-sticky .collection-1-1 {
  float: left;
  width: auto;
  margin: 0px 0px;
  color: #1b1b1b;
  border-bottom: none;
  background-color: #fff;
  display: none;
}

.dropppdown-content-sticky .collection-1-1 a {
  color: #1b1b1b !important;
  font-weight: lighter !important;
}

.mostrar {
  background-color: #021E7C;
}

.eliminar {
  background-color: #292f41;
}


.electronica :hover .collection-1-1 {
  display: block !important;
}

.dropppdown-content-sticky .collection-1-2 {
  float: left;
  width: auto;
  margin: 0px 0px;
  color: #1b1b1b;
  border-bottom: none;
  border-left: none;
}

.dropppdown-content-sticky .collection a {
  color: #1b1b1b;
}

.dropppdown-content-sticky h5 {
  font-size: 15px !important;
  color: #8479C2 !important;
}

.btn-departamentos-sticky:hover .dropppdown-content-sticky {
  display: block;
}


.dropaColor {
  color: #021E7C !important;
  /*#999696 */
  text-decoration-line: underline;
}

.btn-departamentos-sticky a {
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-departamentos-sticky a span {
  margin-right: 3px;
  margin-bottom: 2px;
}

.droppdown-content-2 {
  display: none;
}

.droppdown-content-3 {
  display: none;
}

.droppdown-content-4 {
  display: none;
}

.droppdown-content-5 {
  display: none;
}

.blog {
  display: block;
}

.bordes-top {
  border-top: 1px solid #eee;
}

.bodes-boton-none {
  border-bottom: none;
}

.bordes-rigth-none {
  border-right: non;
}

.bordes-bottom {
  border-bottom: 1px solid #eee !important;
}

.droppdown {
  height: 30px;
  display: flex;
  justify-content: center;
  width: 7%;
}

.droppdown a {
  color: #1b1b1b !important;
  font-weight: bold;
}

.droppdown-content-1 {
  height: auto;
  display: none;
  width: auto;
  position: absolute;
  top: 115px;
  min-width: auto;
  z-index: 100 !important;
  transition: 0.5s !important;
}

.droppdown-content-1 .collection-2 {
  float: left;
  width: auto;
  margin: 0px 0px;
  color: #1b1b1b !important;
  border-bottom: none;
  background-color: #9e9e9e;
}

.droppdown-content-1 .collection-2 a {
  color: #1b1b1b !important;
}

.droppdown:hover .droppdown-content-1 {
  display: block;
}

.droppdown-2,
.droppdown-3,
.droppdown-4 {
  font-size: 15px;
}






/* -- SECTION DE BUSQUEDA ---- */

/*sidenav*/
.buscar-con-side-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

.buscar-con-side-nav .row {
  width: 90%;
  display: flex;
  justify-content: center;


}

.boton-para-sacar-side-nav {
  width: 10%;
  display: flex;
  justify-content: center;

}

.boton-para-sacar-side-nav a {
  margin-top: 7px;
  font-weight: bold;

}

.buscar-con-side-nav .botton-busqueda {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  height: 22px;
  width: 40px !important;
  right: -44px;
  margin-top: 21px;
  border-radius: 5px !important;
  background-color: #616161;
  box-shadow: none !important;
}

.buscar-con-side-nav .botton-busqueda i {
  font-size: 21px;
  color: #fff !important;
  margin-top: 5px;
}

.buscar-con-side-nav .botton-busqueda:hover {
  background-color: #616161;
}

.buscar-con-side-nav .bloque-buscar input[type=text] {
  padding: 0px 20px !important;
  border-radius: 5px !important;
  margin-top: 27px;
  height: 20px !important;
  /*border: 2px solid #616161 !important;*/
  border: 2px solid #021E7C !important;
  box-shadow: none !important;
  width: 100%;
  background-color: #f5f5f5;
}

.buscar-con-side-nav .input-field input[type=text]:focus {
  /*border: 2px solid #616161 !important;*/
  border: 2px solid #021E7C !important;
  box-shadow: none !important;
}


@media screen and (max-width:400px) {
  .buscar-con-side-nav .row {
    width: 100%;

  }

  .buscar-con-side-nav .bloque-buscar input[type=text] {
    width: 90%;
  }

  .buscar-con-side-nav .botton-busqueda {
    right: -20px;

  }
}

@media screen and (max-width:260px) {
  .buscar-con-side-nav .row {
    width: 100%;

  }

  .buscar-con-side-nav .bloque-buscar input[type=text] {
    width: 75%;
  }

  .buscar-con-side-nav .botton-busqueda {
    right: 10px;

  }
}

/*end side nav */

.logotipo-input-sticky {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 50px;

}

.logotipo-input-sticky .row {
  width: 600px;
  display: flex;
  justify-content: flex-end;


}


/*seccion de busqueda */
.boton-search-mobil {
  display: none;
}

.logotipo-input-sticky input[type=text] {
  max-width: 600px !important;
  min-width: 375px !important;
  height: 20px !important;
  /*border-bottom: 2px solid #616161 !important ;*/
  border: 1px solid #929292 !important;
  border-radius: 10px !important;
  padding: 5px !important;
  box-shadow: none !important;
  margin-top: 27px !important;
  box-shadow: none !important;
  color: #212121 !important;
  background-color: #fff !important;

}

.logotipo-input-sticky input[type=text]::placeholder {
  color: #212121 !important;

}

.logotipo-input-sticky input[type=text]:focus {
  /*border-bottom: 2px solid #616161 !important;*/
  border: 1px solid #929292 !important;
  box-shadow: none !important;
}


/*fin de seccion de busqueda */



.contenedor-link-sticky {
  width: 120px !important;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logotipo-link-sticky {
  min-width: 30px;
  font-size: 15px;
  height: 50px;
  display: flex;
  align-items: center;
}


.logotipo-link-sticky a {
  color: #1b1b1b;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 10px;
  color: #fff;

}

.logotipo-link-sticky a i {
  margin-left: px;
  font-size: 25px;
  color: #1b1b1b;
}



.logotipo-sticky {
  height: 100%;
  width: 70px;
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

}

.logotipo-sticky img {
  width: 70%;
  height: 70%;
  object-fit: contain;
  margin-top: 5px;
  filter: invert(0.4);
}

.display-bottom {
  display: none;
}

@media screen and (max-width:1200px) {

  .logotipo-input-sticky .row {
    justify-content: center;
  }

}

@media screen and (max-width:1060px) {



  .logotipo-input-sticky {
    width: 90%;
  }

  .logotipo-input-sticky .row {
    width: 100%;

  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-link-sticky {
    width: auto;
  }

}

@media screen and (max-width:1000px) {

  .logotipo-input-sticky {
    width: 700px;
  }

  .logotipo-input-sticky .row {
    width: 300px;
    justify-content: center;

  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-link-sticky {
    min-width: auto;
  }

}

@media screen and (max-width:800px) {
  .logotipo-input-sticky {
    width: 90% !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }





  .boton-search-mobil {
    display: block;
  }

  .boton-search-mobil i {
    color: #1b1b11;
    font-size: 30px;
  }

  .display-no-mobil-900 {
    display: none;
  }

  .esconde-input-seach {
    display: none !important;
  }

  .esconde-menu-iniciar-idiomas {
    display: none;
  }

  .logotipo-input-sticky {
    width: 500px;
  }

  .logotipo-input-sticky .row {
    width: 300px;


  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-link-sticky {
    min-width: auto;
  }

}

@media screen and (max-width:550px) {
  .logotipo-sticky img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    margin-top: 5px;
  }

  .logotipo-input-sticky {
    width: 250px !important;
    justify-content: center;
    margin-right: 20px;
  }

  .logotipo-input-sticky .row {
    width: 100%;
    justify-content: center;

  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-input-sticky input[type=text] {
    max-width: auto !important;
    min-width: auto !important;
    width: 100% !important;


  }

  .logotipo-link-sticky {
    min-width: auto;
  }

  .logotipo-sticky a {
    font-size: 20px;
    color: #000 !important;

  }

}

@media screen and (max-width:400px) {
  .boton-search-mobil i {
    font-size: 25px;
  }

  .logotipo-input-sticky {
    width: 170px;
  }

  .logotipo-input-sticky .row {
    width: 100%;

  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-link-sticky {
    min-width: auto;
  }


}

@media screen and (max-width:320px) {

  .logotipo-input-sticky {
    width: 140px;
  }

  .logotipo-input-sticky .row {
    width: 100%;

  }

  .contenedor-link-sticky {
    width: auto;

  }

  .logotipo-link-sticky {
    min-width: auto;
  }


}

.esconder-bus {
  display: none;
}


.esconder-bus {
  display: none;
}

/*****fin de menu sticky************/












/* ------------------------- */
/* CARROSEL DE SERVICIOS */
/* ------------------------- */
.servicios-contenedor {
  margin-top: 10px !important;
  display: none !important;
}

.respon-mas-servicios {
  width: 100%;
  display: flex !important;
  justify-content: center;

}

.item-carrosel-categorias-menu {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  background-color: #021E7C;
}

.links-carrosel-pub-menu {
  display: flex;
  flex-direction: column;

}

.imgen-carrosel-pub-menu {
  width: 100% !important;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5 !important;
  flex-direction: column;
  align-items: center;



}

.imgen-carrosel-servicios {
  width: 98% !important;
  border: 1px solid rgb(221, 116, 116);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5 !important;
}

.imgen-carrosel-pub-menu img {
  width: 100% !important;
  height: 100% !important;
  /*border: 2px solid rgba(114, 112, 231, 0.5);*/
  margin-top: 5px;

}

.links-carrosel-pub-menu:hover .imgen-carrosel-pub img {
  border: 1px solid #bdbdbd;
}

.links-carrosel-pub-menu:hover .titulo-carrousel-pub p {
  border-bottom: 5px solid transparent;

}

.titulo-carrousel-pub-menu {
  width: 100px;
  height: 40px;
  text-align: center;
}

.imgen-carrosel-pub-menu p {
  color: #1b1b1b;
  font-size: 16px !important;
  margin: 0px 3px;
}

@media screen and (min-width:1200px) {

  .servicios-contenedor {
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .respon-mas-servicios {
    width: 950px;



  }

}

@media screen and (max-width:700px) {
  .respon-mas-servicios {
    width: 100%;
  }

  .carrosel-cat-menu {
    width: 100%;
  }

}

/* ------------------------- */
/* FIN DEL CARROSEL DE SERVICIOS */
/* ------------------------- */


/******LOCALIZACIO****************/
.containerLocalizacion {
  width: 100%;
  display: flex;
  justify-content: center;

}

.containerLocalizacion h1 {
  text-align: center;
  font-size: 18px;
  color: #212121 !important;
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: 600;
}

/****** FIN LOCALIZACIO ****************/


/********************************************************/
/********* SECTION DE CARDS    **********/
/********************************************************/

.fila-de-targetas-horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;

}

.cardCategoriaHorizotalContainer {
  display: flex;
  width: 95%;
  height: auto;
  /*border-radius: 10px;*/
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  overflow: hidden;
}

.cardCategoriaHorizotal {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color:
    /*#f1f8e9*/
    #fff;

}

.cardHorizotalfoto {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  width: 350px;
  display: flex;
  align-items: center;
}

.cardHorizotalfoto img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #fff;
}

.cardHorizotalText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

}

.cardHorizotalDescription {
  text-align: left;
}

.cardHorizotalDescriptionText {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  color: #212121;
}

.cardHorizontalProductTiempo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -14px;
}

.cardHorizontalProductTiempo h6 {
  font-weight: 600;
  font-size: 11px;
  color: rgb(13, 1, 70);
}

.cardHorizontalProductTiempo .iconRelog {
  color: rgb(250, 0, 0);
  margin-right: 2px;
}

.cardHorizotalprecio {
  margin-top: -5px;
  font-size: 20px !important;
  color: rgb(243, 19, 19);
  text-align: left;

}



@media screen and (max-width:1050px) {}

@media screen and (max-width:800px) {

  .cardCategoriaHorizotalContainer {

    margin-left: 0px;
    width: 100%;

  }

}

@media screen and (max-width:750px) {
  .cardHorizotalfoto {}

  .cardHorizotalDescriptionText {
    font-size: 17px;

  }
}

@media screen and (max-width:600px) {
  .cardHorizotalfoto {
    min-width: 200px;
    width: 250px;
  }
}

@media screen and (max-width:500px) {
  .cardHorizotalfoto {
    min-width: 200px;
    width: 230px;
  }

  .cardHorizotalDescriptionText {
    font-size: 16px;

  }
}

@media screen and (max-width:400px) {
  .cardHorizotalfoto {
    min-width: 200px;
    width: 200px;
  }
}

@media screen and (max-width:350px) {
  .cardHorizotalfoto {
    width: 150px;
    min-width: 150px;
  }

  .cardHorizotalDescriptionText {
    font-size: 15px;

  }
}

@media screen and (max-width:320px) {
  .cardHorizotalfoto {
    width: 120px;
    min-width: 120px;
  }
}






/******************fin de car horizonat*****************/
.containerMenuCard100 {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-bottom: 150px !important;*/



}

.containerMenuCard100Cat {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  /*min-height: 700px;*/
  margin-bottom: 200px !important;
  height: auto;

}

.containerMenuCard {
  width: 1300px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.puedesFiltrarPor{
  display: flex;
  justify-content: center;
}
.puedesFiltrarPor h6{
  font-weight: 600;
}
.menuLateral {
  position: sticky;
  margin-top: 5px;
  top: 60px;
  background-color: #fff;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  height: 400px;
  border-radius: 10px;
  border: 2px solid #eee;

  /*display: none;*/
  /*margin-right: 5px;*/
}
.container-btn-filter{
  display: flex;
  justify-content: center;
}

.container-btn-filter button{
  background-color: blue !important ;
}

.select-filter{

}

.select-filter select{
  border: 2px solid #9e9e9e !important;
  height: 35px;
  border-radius: 5px;
  box-shadow: none !important;
}
.nuevos-productos {
  width: 100%;
  /* border-right: 2px solid rgba(7, 48, 233, 0.1);  */


}

.nuevos-productosHome {
  width: 950px;
  /*800px*/
  /* border-right: 2px solid rgba(7, 48, 233, 0.1);  */
  line-height: 40px;
}

.respon-nuevos-productos {}

.fila-de-targetas {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.encabezado-targetas {
  height: 20px;
  margin-top: -55px;
  margin-left: 20px;
  margin-bottom: 15px;
}

.contenedor-de-targetas {
  padding: 20px 0px;
}

.ver-prod-targe {

  justify-content: flex-start;

}

.ver-prod-targe-p p {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 20px !important;
}

.encabezado-targetas {
  width: 100%;
}

.targeta {
  position: relative;
  /*
  width: 180px;
  height: 250px;*/
  width: 170px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  /*
 background: #ECE9E6;  
 background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  
 background: linear-gradient(to right, #FFFFFF, #ECE9E6); */
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid #eee;
  margin: 5px 5px;

  /*#d3cfcf*/
  ;


}

/*
.targeta:hover{
 background-color: rgb(199, 199, 199);
}
*/
.imagen-targeta {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.targeta img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenedor-imagen {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
}

.descripcion-de-la-targeta {
  position: relative;
  width: 100%;
  margin-left: 10px;
  margin-top: -10px;
}

.descripcion-de-la-targeta p {
  margin: 0px 0px;
  font-weight: bold;
  line-height: 1.2;
  color: #424242;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  overflow-wrap: break-word;
}

.descripcion-de-la-targeta a {
  padding-top: 5px;
  font-weight: bold;
  padding-bottom: 5px;
  margin: 5px 0px;
  line-height: 1.2;
  color: #424242;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  overflow-wrap: break-word;
  font-size: 14px;
}

.descripcion-de-la-targeta a:hover {
  text-decoration-line: underline;
}

.precio {
  font-size: 18px;
  font-weight: 600;
  font-weight: bold;
  color: red;
  margin-bottom: 5px !important;
}

.visto {
  width: 30%;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}

.stock-productos {
  display: none !important;
  position: absolute;
  color: #757575;
  top: 0px;
  left: 30px;
  width: 40px;
  height: 50px;
  display: flex;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  justify-content: center;
  background: #ECE9E6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}



@media screen and (max-width:1180px) {
  .nuevos-productos {
    width: 720px;

  }


  .targeta {
    width: 170px;
    height: 220px;

  }

  .descripcion-de-la-targeta a {
    font-size: 15px;
  }

}

@media screen and (max-width:1060px) {
  .nuevos-productosHome {
    width: 100%;
  }

  .targeta {
    width: 170px;
    height: 220px;

  }

  .descripcion-de-la-targeta a {
    font-size: 15px;
  }

}

@media screen and (max-width:920px) {
  .nuevos-productos {
    width: 650px;


  }

  .targeta {
    width: 150px;
    height: 220px;

  }

  .descripcion-de-la-targeta a {
    font-size: 15px;
  }

}

@media screen and (max-width:860px) {
  .nuevos-productos {
    width: 550px;


  }

  .targeta {
    width: 170px;
    height: 220px;

  }

  .descripcion-de-la-targeta a {
    font-size: 15px;
  }

}

@media screen and (max-width:800px) {
  .nuevos-productos {
    width: 100%;

  }

  .menuLateral {
    display: none;
  }

  .targeta {
    height: 250px;
    width: 160px;


  }
}

@media screen and (max-width:710px) {
  .precio {
    font-size: 17px;
  }


}


@media screen and (max-width:670px) {

  .targeta {

    width: 30%;


  }
}

@media screen and (max-width:590px) {}


@media screen and (max-width:570px) {
  .targeta {
    width: 150px;

  }

  .precio {
    font-size: 17px;
  }

  .targeta {

    width: 31%;

  }


}

@media screen and (max-width:490px) {
  .fila-de-targetas {
    justify-content: space-around;
  }

  .targeta {
    width: 48%;


  }

  .targeta {
    margin: 2px 0.5px;


  }

  .precio {
    font-size: 17px;
  }

}

@media screen and (max-width:460px) {


  .precio {
    font-size: 17px;
  }

  .ver-prod-targe-p p {
    text-align: center;
    margin-left: auto;
    margin-bottom: 0px;
    font-size: 17px !important;
    margin-bottom: 20px;
    margin-top: 40px;
  }


}

@media screen and (max-width:420px) {


  .precio {
    font-size: 19px;
  }


}

@media screen and (max-width:360px) {

  .precio {
    font-size: 17px;
  }

}

@media screen and (max-width:330px) {

  .precio {
    font-size: 17px;
  }

}

@media screen and (max-width:290px) {


  .precio {
    font-size: 17px;
  }

}

/* ************************************************************* */
/* ******* FIN DE LA SECCION DE CARDS***** */
/* ************************************************************* */




/* ************************************************************* */
/* *******   SECTION DE MENU ABAJO***** */
/* ************************************************************* */


.contenedor-fluido-abajo {
  display: none;
}

.contenedor-fluido-abajo {
  position: fixed;
  bottom: 0px;
  left: 0px;
  /*background-color: #ECE9E6;*/
  background-color: #efebe9;
  width: 100%;
  z-index: 100;
  display: none;
  justify-content: center;
  border-top: 1px solid #e0e0e0;

}

.contenedor-abajo {
  width: 100%;
  height: 40px;
  display: flex;
}

.contenedor-abajo-link {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-menu-abajo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.link-menu-abajo span {
  font-size: 12px !important;
  color: #212121;
}

.link-menu-abajo i {
  color: #616161;
  font-size: 30px !important;
}

.link-menu-abajo p {
  color: #1b1b1b;
  font-size: 14px !important;
}

@media screen and (max-width:800px) {
  .contenedor-fluido-abajo {
    display: block;
  }
}

@media screen and (max-width:600px) {
  .contenedor-abajo {}

  .link-menu-abajo i {
    font-size: 22px !important;
  }

  .link-menu-abajo p {
    font-size: 14px !important;
  }

}

@media screen and (max-width:500px) {
  .contenedor-abajo {}

  .añadir-product {}

  .añadir-product i {
    margin-top: 5px;
    color: #050505 !important;
    font-size: 25px !important;

  }

}

@media screen and (max-width:380px) {
  .contenedor-abajo {}
}

@media screen and (max-width:300px) {
  .contenedor-abajo {}
}


/* ************************************************************* */
/* *******   FIN DE LA SECTION DE MENU ABAJO***** */
/* ************************************************************* */



/**** SideNav utilizado*/
#slide-out-nav {
  z-index: 30000;
  width: 90% !important;
}

#slide-out-navFilter {
  z-index: 30000;
  width: 90% !important;
}

/*****filter style******/
.sideNavPuedesFiltrarPor {
  display: flex;
  justify-content: center;
}

.sideNavPuedesFiltrarPor h6 {
  font-weight: bolder;
  font-size: 14px;
}

.sideNavFilter select {
  text-align: center;
  font-size: 15px !important;
}

.sideNavbtnFiltrar {
  display: flex;
  justify-content: center;
}

.sideNavbtnFiltrar button {
  border-radius: 5px !important;
  box-shadow: none !important;
  background-color: blue !important;
  font-weight: bolder;
  color: #eee;
}

/*****end filter style******/

.collection-item {
  height: auto;
  font-weight: bolder;
}

.avatar-collecion {
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 16px;
}

.avatar-collecion img {
  width: 4%;
  height: 4%;
  margin-right: 10px;
}

@media screen and (max-width:700px) {
  .avatar-collecion img {
    width: 6%;
    height: 6%;
    margin-right: 10px;
  }
}

@media screen and (max-width:500px) {
  .avatar-collecion img {
    width: 8%;
    height: 8%;
    margin-right: 10px;
  }
}

@media screen and (max-width:350px) {
  .avatar-collecion img {
    width: 10%;
    height: 10%;
    margin-right: 10px;
  }
}

/***Fin de sideNav final***/



/* ------------------------- */
/*seccion  de footer*/
/* ------------------------- */
.page-footer {
  /*background: #ECE9E6 !important; */
  background:
    /* #ECE9E6*/
    #1F89E5 !important;
  margin-top: 200px;
}

.redes-sociles-footer {
  width: 100%;
  height: 60px;
  /*background: #ECE9E6; */
}

.informacion-footer-1 {
  width: 100%;
  height: 40px;
  /*background: #ECE9E6; */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.informacion-footer-1 a {
  height: auto;
}

.informacion-footer-2 {
  width: 100%;
  height: 30px;
  /*background: #ECE9E6; */
  text-align: center;
  color: #252727;
}

.informacion-footer-1 a {
  text-decoration: none;
  color: #252727;
}

.informacion-footer-1 a:hover {
  color: #e65100;
  transition: 0.5s;
}

.barra-de-separacion-footer {
  color: #424242;
  margin: 0 5px;
}

.barra-de-redes-sociales-footer a {
  text-decoration: none;
}

.barra-de-redes-sociales-footer {
  position: absolute;
  width: 100%;
  height: auto;
}

.contenedor-de-sociales-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-social-footer {
  text-decoration: none;
  width: 35px !important;
  height: 35px !important;
  margin-left: 5px;
  margin-top: 10px;
  background-color: #424242 !important;
  color: #f8f1f1 !important;
  border: 2px solid #424242 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none !important;
}

.btn-social-footer i {
  font-size: 20px !important;
  color: #beb8b8 !important;
}



.separacion-footer {
  position: relative;
  width: 35px;
  height: 35px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #424242;
  justify-content: center;
  margin-left: 5px;
  margin-top: 10px;
}

.link-contenido-footer {
  text-align: center;
}



@media screen and (max-width:500px) {

  .btn-social-footer {
    width: 32px !important;
    height: 32px !important;
    margin-left: 5px;
  }

  .btn-social-footer i {
    font-size: 16px !important;
  }
}

/* ------------------------- */
/*FIN DE LA seccion final de footer*/
/* ------------------------- */

/* ------------------------- */
/* FORMULARIO AGRAGAR */
/* ------------------------- */

.formulario-agregar-respon {
  margin-top: 100px !important;
  display: flex;
  justify-content: center;

}

.formulario-agregar-form {
  width: 400px;
  height: auto;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px !important;
  /*background-color: #ECE9E6 !important;*/
  background-color: white;
  border: 1px solid rgb(189, 189, 189);
}

.browser-default {
  background-color: white !important;
}

#pais {
}

.color-select select {
  box-shadow: none !important;
}

textarea {
  padding-left: 10px !important;
  box-shadow: none !important;

}

.contenedor-spinner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.spinners {
  display: flex;
  justify-content: center;
}

label {
  color: #9E9E9E !important;
}

.editor-de-texto-contenedor {
  position: relative;
  width: 100%;
  max-width: 400px;
  cursor: auto;

}

.ckditor {
  text-align: left !important;
}

#input2 {
  box-shadow: none !important;
  border-bottom: 2px solid red;
  padding-left: 10px !important;
}

#input1 {
  box-shadow: none !important;
  border-bottom: 2px solid red;
  padding-left: 10px !important;
}

#precio {
  box-shadow: none !important;
  border-bottom: 2px solid red;
  padding-left: 10px !important;
}

#nombre {
  box-shadow: none !important;
  border-bottom: 2px solid red;
  padding-left: 10px !important;
}

#descripcion {
  border-bottom: 2px solid red;
}

#localizacion {
  border-bottom: 2px solid red;
}

.precios input {
  box-shadow: none !important;
  border-bottom: 2px solid blue;

}

.texto-opcional p {
  display: flex;
  text-align: center !important;
  color: #7e7e7e !important;
  margin-left: 13px !important;
}

.contenedor-de-input {
  position: relative;
  margin-top: 30px !important;
}

.file-select {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.form-img-preview {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.img-preview {
  width: 100px;
  height: auto;
}

.img-preview .btn {
  box-shadow: none !important;

}

.foto-principal {
  box-shadow: none !important;
}
/**previ actual img select**/
.imagenes-elegidas{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.imagenes-elegidas img{
  width: 48%;
  margin: 3px;
}
/***********/
.img-preview img {
  width: 100%;
}

.file-select::before {
  background-color: #efebe9 !important;
  color: #212121;
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "Selecciona";
  border-radius: 3px;
  /* testo por defecto */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  font-weight: 600;


}

.file-select input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 30px;
  font-size: 15px !important;
  box-shadow: none !important;
}

#src-file1::before {
  content: "Imagenes maximo 4";


}
#src-fileRegist::before {
  content: "Selecciona el logo";

}

#src-file2::before {
  content: 'foto 1 ';
}

#src-file3::before {
  content: 'foto 2 ';
}

#src-file4::before {
  content: 'foto 3 ';
}

.form-previ-arti {
  width: 220px !important;
}

.input-submit-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px !important;
}

.input-submit-btn .btn {
  background-color: blue !important;
  color: #eee;
  box-shadow: none !important;
}

.eliminar-imagen {
  display: flex;
  justify-content: center;
}

.eliminar-imagen i {
  font-size: 40px !important;
  color: red;
}




@media screen and (max-width:420px) {
  .editor-de-texto-contenedor {
    position: relative;
    width: 100%;
    max-width: 375px;
  }
}

@media screen and (max-width:370px) {
  .editor-de-texto-contenedor {
    position: relative;
    width: 100%;
    max-width: 350px;
  }

}

@media screen and (max-width:350px) {
  .editor-de-texto-contenedor {
    position: relative;
    width: 100%;
    max-width: 315px;
  }

  .img-preview {
    width: 90px;
    height: auto;
  }
}





/* ------------------------- */
/* FIN DE FORMULARIO AGRAGAR */
/* ------------------------- */



/***********************************************************************************/
/* ------------------------- */
/* PRODUCTO INFO PRODUCTO */
/* ------------------------- */
/*
.containerGlobal {
  margin-bottom: 100px;
}

.productRelaText {
  width: 100%;
  display: flex;
  justify-content: center;
}

.productRelaText span {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}



.product-info {
  width: 100%;
  margin-top: 20px;
  display: flex;
  height: auto;
  justify-content: center;
}

.product-info-respon {
  width: 500px;
  height: auto;
  background-color: transparent;
  margin-bottom: -60px;
}

.articulo-description {
  width: 100%;
  flex-direction: column;
}

.articulo-description-info-1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.articulo-description-info-2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-name {
  font-size: 30px !important;
  font-weight: 600;
}

.product-description {
  font-size: 18px !important;
  font-weight: 500;
  margin-top: -1px;
  margin-bottom: 5px;
}

.product-sub {
  font-size: 20px !important;
  font-weight: 700;
  margin-bottom: 0px;
  color: #212121 !important;
}

.product-price {
  font-size: 30px !important;
  font-weight: 500;
  color: red;
  margin-block: -1px;
}


.localizacioTextProduct {
  color: #212121 !important;
  font-size: 17px !important;
  font-weight: 500;
}


.product-info-contacto {
  display: flex;
  flex-direction: column;

}

.contactCenterWordl {
  display: flex;
  width: 100%;
  justify-content: center;
}

.product-info-contacto-link {
  width: 100%;
  display: flex;
  justify-content: center;

}

.product-info-contacto-link a {
  font-size: 40px;
  margin-top: 10px;

}

.product-info-contacto-link .whatsapp {
  color: #35db1b !important;

}

.product-info-contacto-link .telefono {
  margin-left: 20px;
  color: #1b75eb;
}

*/
/********   CONTENEDOR DEL CARROUSE DE IMAGENES DEL PRODUCTO   ********/
/*
.ver-produto-contenedor {
  margin-top: 70px !important;
  display: flex;
  height: auto;
  justify-content: center;

}

.ver-produto-respon {
  width: auto;
  height: auto;
  display: flex;

}

.ver-producto-img {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s !important;

}

.imagen_ver_producto {
  width: 100% !important;
  height: 100% !important;
  transition: 0.02s;
}

.ver-producto-img img {
  width: 100%;
  height: 100%;
  transition: 0.02s;
}

.item img {
  border-radius: 10px;
  border: 2px solid #fff;
  width: 100%;
  height: 100%;
  transition: 0.02s;
}

.ver-producto-elegir-img {
  width: 120px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  margin-right: 20px !important;

  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;

}

.ver-img-item {
  width: 100px;
  height: auto;
  margin: 5px 5px;

}


.ver-img-item img {
  width: 100%;
  transition: 0.02s;
  border: 2px solid transparent;
}
*/
@media screen and (max-width:580px) {
 
/*
  .ver-producto-img {
    background-color: transparent;
  }

  .ver-producto-elegir-img {
    background-color: transparent;
  }

  .ver-produto-contenedor {
    margin-top: 60px !important;
    margin-bottom: 10px !important;

  }

  .margin-top-description {
    margin-top: -100px;
  }
*/
  /***border-abajo****/
  /*
  .border-abajo {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

  }

  .top-margen {
    margin-top: 70px;
  }
*/
  /****fin de border abajo****/
  /*
  .ver-produto-respon {
    width: 100%;
    flex-direction: column-reverse;
    height: auto;

  }

  .ver-producto-img {
    width: 100%;
    height: auto;
    margin-right: 0px !important;
    transition: 0.2s;

  }

  .ver-producto-img img {
    width: 100%;
    display: flex;
    transition: 0.2s;



  }

  .ver-producto-img img {
    width: 100%;
    transition: 0.02s;
  }

  .ver-producto-elegir-img {
    width: 100%;
    flex-direction: row;
    margin-right: 0px !important;
    margin-top: 10px !important;
    border: 0px !important;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    height: auto;
  }




*/
}



/********   CONTENEDOR DEL CARROUSE DE IMAGENES DEL PRODUCTO   ********/



/* ------------------------- */
/* FIN DE PRODUCTO INFO PRODUCTO */
/* ------------------------- */
/*****************************************************************/





/***************************/
/***********ELEGIR SUBCATEGORIA CON LINK***********/
.elegirsub-cate {
  margin-top: 70px !important;
  display: none;
  justify-content: center;
  /*background-color: rgba(0, 0, 0, 0.1);*/
  /*background-color: #93c3ee;*/
  height: 50px;
  align-items: center;
}

.elegirsub-categ-resp {}

.elegirsub-categ-link .owl-theme {}

.elegirsub-cate a {
  height: 50px !important;
  background-color: #C4C4C4;
  /*background: #ECE9E6 !important;*/
  /*border: 1px solid #b1aeae !important;*/
  border: 1px solid #909090;
  padding: 2px 5px !important;
  border-radius: 15px;
  color: #626161;
  box-shadow: none !important;
  font-size: 17px !important;
  margin-top: 4px;

}

/***********FIN DE ELEGIR SUBCATEGORIA CON LINK***********/

/****seccion de lugares*/
.lugar-anuncio {}

.lugar-anuncio-resp-1 {}

.lugar-anuncio-resp-2 {}

.lugar-anuncio-resp-1 a {}

/****fin seccion de lugares*/





/***********LiNK CATTEGORIA-SUBCATEGORIA***********/
.cont-cate-margin-top {}

.containerSubcategoria {}

.containerSubcategoriaResp {}

.fila-de-targetas-subcategorias {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.containerSubcategoriaResp a {
  height: 30px !important;
  background-color: #353232;
  /*background: #ECE9E6 !important;*/
  /*border: 1px solid #b1aeae !important;*/
  display: flex;
  align-items: center;
  border: 1px solid #686666;
  padding: 2px 8px !important;
  border-radius: 22px;
  color: #ddd8d8;
  box-shadow: none !important;
  font-size: 13px !important;
  margin: 4px 3px;

}

@media screen and (max-width:600px) {
  .containerSubcategoriaResp a {
    height: 32px !important;
    background-color: #353232;
    /*background: #ECE9E6 !important;*/
    /*border: 1px solid #b1aeae !important;*/
    display: flex;
    align-items: center;
    border: 1px solid #686666;
    padding: 2px 8px !important;
    border-radius: 22px;
    color: #ddd8d8;
    box-shadow: none !important;
    font-size: 15px !important;
    margin: 4px 3px;
    font-weight: bolder;

  }
}


/***********FIN LiNK CATTEGORIA-SUBCATEGORIA***********/



/************************************************************/
/*************************COLECCION PARA BUSCAR ***********/
/*****************************************************************/
.collection-para-buscar {
  position: fixed !important;
  display: flex;
  flex-direction: column;
  top: 41px;
  width: 50% !important;
  z-index: 100 !important;
  left: 25%;
}

.collection-para-buscar a {
  color: #021E7C !important;
}

@media screen and (max-width:1400px) {
  .collection-para-buscar {
    position: fixed !important;
    display: flex;
    flex-direction: column;
    width: 70% !important;
    z-index: 100 !important;
    left: 15%;
  }
}

@media screen and (max-width:1100px) {
  .collection-para-buscar {
    position: fixed !important;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    z-index: 100 !important;
    left: 0px;
  }
}

/************FIN DE COLLCTION PARA BUSCAR****************/

/****************************/
.carousel {
  width: 100%;

}

/**********************************************************************/
/************************  PROFIL DEL USUARIO  *****************/
/**********************************************************************/
.profilContainer {
  margin-top: 60px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 5px;
}

.profilContainer p {
  font-weight: 500;
}





/************** ESTILOS DE PROFIL ************************/
.numberOfProducts {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  text-align: center;

}

.numberOfProducts p {
  font-weight: 500;

}

.numberOfProducts h1 {
  margin-top: -10px;
  color: #c40909;
  font-weight: 600;

}

.profilContainerCard {
  width: 100%;
  display: flex;
  justify-content: center;


}

.profilContainerCardResp {
  width: 1000px;
  margin-bottom: 70px;

}

.cardAnuncioContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 10px;
  background-color:
    /*#f1f8e9*/
    #ffffff;
  margin-bottom: 20px;
}

.cardAnuncioAnuncio {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;


}

.foto {
  min-width: 220px;
  max-width: 220px;
  height: auto;
  border-radius: 10px;
}

.foto img {
  width: 100%;
  object-fit: cover;
}

.anuncioText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;

}

.description {
  text-align: center;
}

.descriptionText {
  font-size: 15px !important;
  font-weight: 500;
}

.precioProfil {
  margin-top: -20px;
  font-size: 25px !important;
  color: rgb(243, 19, 19);

}

.tiempoHace {
  font-size: 13px !important;
  font-weight: 500;
  color: rgb(104, 4, 4);
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cardAnuncioBotones {
  display: flex;
  width: 100%;
}

.cardAnuncioBotonesCenter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cardAnuncioBotonesCenter a {
  margin: 5px;
  border-radius: 5px !important;
  height: 30px !important;
}

.eliminar {
  background-color: #c62828 !important;
}

.editar {
  background-color: rgb(52, 131, 250) !important;
}

.desactivar {
  background-color: #212121 !important;
}

@media screen and (max-width:1050px) {
  .profilContainerCardResp {
    width: 100%;
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .cardAnuncioContainer {
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
  }
}

@media screen and (max-width:750px) {
  .foto {
    min-width: 250px;
    max-width: 250px;
  }
}

@media screen and (max-width:600px) {
  .foto {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .foto img {
    width: 90%;

  }

  .cardAnuncioAnuncio {
    flex-direction: column;
  }
}

/****************fin del profil *******************/


/********** SI NO LO USAS IMAGEN CONTAINER ***************/
.imgPubSinoLoUsas {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 30px !important;

}

.imgPubSinoLoUsasResp {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 860px;
  
  flex-wrap: wrap;
  height: auto;
  border-radius: 10px !important;
  
  
}

.imgPubSinoLoUsasResp img {
  width: 100%;
  height: 100%;
  border-radius: 10px !important;
  z-index: -1;
  background-color: #FE981D ;

}

.letra-publicidad{
  position: absolute;
  display: flex;
  align-items: center;
  max-width:800px ;
  height: 50%;
  min-height: 200px;

}
.letra-publicidad p{
  font-size: 100px !important;
  color:#fff;
  -webkit-text-stroke:1px rgb(3, 0, 0);
  font-weight: 800;
}
@media screen and (max-width:770px) {
  .letra-publicidad{
    height: 60%;
  }
  .letra-publicidad p{
    font-size: 90px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:670px) {
  .letra-publicidad{
    height: 52%;
  }
  .imgPubSinoLoUsasResp {

    background-color: none !important;
    border-radius: 0px !important;
    
  }
  
  .imgPubSinoLoUsasResp img {

    border: none !important;
    border-radius: 00px !important;
    
  }
  
}
@media screen and (max-width:620px) {
  .letra-publicidad{
    height: 45%;
  }
  .letra-publicidad p{
    font-size: 80px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:550px) {
  .letra-publicidad{
    height: 40%;
  }
  .letra-publicidad p{
    font-size: 70px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:480px) { 
  .letra-publicidad{
    height: 35%;
  }
  .letra-publicidad p{
    font-size: 60px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:420px) {
  
  .letra-publicidad p{
    font-size: 50px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:390px) {
  .letra-publicidad{
    height: 25%;
  }
  .letra-publicidad p{
    font-size: 45px !important;
    font-weight: 800;
  }
}


@media screen and (max-width:340px) {
  .letra-publicidad{
    height: 30%;
    
  }
  .letra-publicidad p{
    font-size: 35px !important;
    font-weight: 800;
  }
}
@media screen and (max-width:300px) {
  .letra-publicidad{
    height: 15%;
  }
  .letra-publicidad p{
    font-size: 30px !important;
    font-weight: 800;
    margin-bottom: 80px;
  }
}
@media screen and (max-width:280px) {
  .letra-publicidad{
    height: 20%;
  }
  .letra-publicidad p{
    font-size: 30px !important;
    font-weight: 800;
    margin-bottom: 100px;
  }
}
/***********************************************************/

/*********  CONFIRMAR REGISTRO  *************************/
.confirmContainer {
  height: 40rem;
  align-items: center;

}

.confirmContainerResp {
  height: 100%;
  align-items: center;
}

.confirmContainerLoader {
  height: 100%;
  /*margin-top: -50px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmContainerLoader p {
  font-weight: 700;
  font-size: 15px !important;
  color: #021E7C;
}

.loaderSpinnerConfirm {}

.containerFormCodigo {
  height: 100%;
  /*margin-top: -50px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-inline: 5px;
}

.containerFormCodigo h1 {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-align: center;
}

.containerFormCodigo input {
  width: 250px !important;
  border: 2px solid blue !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  height: 30px !important;
  text-align: center;
}


/*********  FIN DE CONFIRMAR REGISTRO  *************************/
.scroll-horizontall-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;

}

.scroll-horizontall-container-resp{
    max-width: 900px;
    width: 100%;
    display: none;
    

}
@media screen and (max-width:800px) {
    .scroll-horizontall-container{
        margin-block: 40px;
        margin-top: 60px;

    }
    .scroll-horizontall-container-resp{
        display: block;
    
    }

  
}
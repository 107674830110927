.container-img-profil-agencia {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    /*background-image: url("https://res.cloudinary.com/mumbex/image/upload/v1666769155/ww_y1zoel.webp");*/

    

}

.container-img-profil-agencia-resp {
    max-width: 900px;
    display: flex;
    align-items: center;
}

.container-img-profil-agencia-resp img {
    width: 180px;
    object-fit: contain;
    background-color: #fff;

}
@media screen and (max-width:350px) {
    .container-img-profil-agencia-resp img {
        width: 260px;

    }
}
@media screen and (max-width:280px) {
    .container-img-profil-agencia-resp img {
        width: 230px;
    }
}
@media screen and (max-width:250px) {
    .container-img-profil-agencia-resp {
        max-width: 100%;
    }
    .container-img-profil-agencia-resp img {
        width: 100%;
    }
}